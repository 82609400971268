import React from 'react';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';
import './background.css';

function Background() {
    const particlesInit = async (main) => {
        console.log(main);
        await loadFull(main);
    };

    const particlesLoaded = container => {
        console.log(container);
    };

    return (
        <Particles 
                id='particles-js'
                init={particlesInit}
                loaded={particlesLoaded}
                options={{
                    background: {
                        color: {
                          value: "#1f1f38",
                        },
                    },
                    fpsLimit: 120,
                    interactivity: {
                        events: {
                            onClick: {
                                enable: true,
                                mode: "push",
                            },
                            onHover: {
                                enable: true,
                                mode: "push",
                            },
                            resize: true,
                        },
                        modes: {
                            push: {
                                quantity: 25,
                            },
                            repulse: {
                                distance: 150,
                                duration: 3.5,
                            },
                        },
                    },
                    particles: {
                        color: {
                            value: "rgb(6, 78, 113)",
                        },
                        links: {
                            color: "rgb(6, 78, 113)",
                            distance: 150,
                            enable: true,
                            opacity: 1,
                            width: 2,
                        },
                        move: {
                            direction: "none",
                            enable: true,
                            outModes: {
                                default: "bounce",
                            },
                            random: false,
                            speed: 3,
                            straight: false,
                        },
                        number: {
                            density: {
                                enable: true,
                                area: 600,
                            },
                            value: 60,
                        },
                        opacity: {
                            value: 0.9,
                        },
                        shape: {
                            type: "nothing",
                        },
                        size: {
                            value: { min: 5, max: 5 },
                        },
                    },
                    detectRetina: true,
                }}
        />
    )
}

export default Background