import React, { useState } from "react";
import "./about.css";
import about_me from "../../assets/about_me.JPEG";
import certificate from "../../assets/Certificate.jpg";
import SubSection from "./AboutSubSection";

const About = () => {
  const [toggle, setToggle] = useState(true);

  const DisplayCertificate = () => {
    setToggle(false);
  };

  const HideCertificate = () => {
    setToggle(true);
  };

  return (
    <section id="about">
      <h5>Get to know</h5>
      <h2>About Me</h2>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={about_me} alt="about me photo" />
          </div>
        </div>
        <div className="about__content">
          {toggle ? (
            <SubSection show={DisplayCertificate} />
          ) : (
            <img
              src={certificate}
              alt="Kenzie Certificate"
              className="certificate"
              onClick={HideCertificate}
            />
          )}
          <a href="#contact" className="btn btn-primary">
            Contact me here
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
